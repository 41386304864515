// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bibliografia-js": () => import("./../../../src/pages/bibliografia.js" /* webpackChunkName: "component---src-pages-bibliografia-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-bohaterowie-js": () => import("./../../../src/pages/bohaterowie.js" /* webpackChunkName: "component---src-pages-bohaterowie-js" */),
  "component---src-pages-festung-breslau-js": () => import("./../../../src/pages/festung-breslau.js" /* webpackChunkName: "component---src-pages-festung-breslau-js" */),
  "component---src-pages-formularz-kontaktowy-js": () => import("./../../../src/pages/formularz-kontaktowy.js" /* webpackChunkName: "component---src-pages-formularz-kontaktowy-js" */),
  "component---src-pages-fort-6-js": () => import("./../../../src/pages/fort6.js" /* webpackChunkName: "component---src-pages-fort-6-js" */),
  "component---src-pages-grh-609-d-js": () => import("./../../../src/pages/grh609d.js" /* webpackChunkName: "component---src-pages-grh-609-d-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landsturm-js": () => import("./../../../src/pages/landsturm.js" /* webpackChunkName: "component---src-pages-landsturm-js" */),
  "component---src-pages-luftschutz-obrona-cywilna-js": () => import("./../../../src/pages/luftschutz-obrona-cywilna.js" /* webpackChunkName: "component---src-pages-luftschutz-obrona-cywilna-js" */),
  "component---src-pages-mapa-obiektow-fortyfikacyjnych-js": () => import("./../../../src/pages/mapa-obiektow-fortyfikacyjnych.js" /* webpackChunkName: "component---src-pages-mapa-obiektow-fortyfikacyjnych-js" */),
  "component---src-pages-mecenat-js": () => import("./../../../src/pages/mecenat.js" /* webpackChunkName: "component---src-pages-mecenat-js" */),
  "component---src-pages-slownik-js": () => import("./../../../src/pages/slownik.js" /* webpackChunkName: "component---src-pages-slownik-js" */),
  "component---src-pages-stowarzyszenie-js": () => import("./../../../src/pages/stowarzyszenie.js" /* webpackChunkName: "component---src-pages-stowarzyszenie-js" */)
}

